<template>
  <el-table
    v-show="Object.keys(matrix.cards).length"
    class="matrix-summary-table"
    style="width: 100%; margin-bottom: 25px"
    size="mini"
    border
    :data="summary"
  >
    <el-table-column label="ИТОГО" :width="350"></el-table-column>
    <el-table-column label="Вес КПЭ (план)">
      <el-table-column
        v-for="card in matrix.cards"
        :key="card.id"
        width="200"
        align="center"
      >
        <template slot="header" slot-scope="scope">
          {{ card.user.full_name }}
          <el-divider class="mini"></el-divider>

          <el-tooltip content="Удалить из матрицы" placement="top" :open-delay="500">
            <el-button
              class="table-action-button"
              type="default"
              round
              size="mini"
              icon="fas fa-user-times"
              @click="$emit('remove-card', card.id)"
            >
            </el-button>
          </el-tooltip>
          <el-tooltip content="Открыть карту" placement="top" :open-delay="500">
            <el-button
              class="table-action-button"
              type="default"
              round
              size="mini"
              icon="fas fa-external-link-alt"
              @click="openCardInNewWindow(card.id)"
            >
            </el-button>
          </el-tooltip>

          <el-tooltip
            v-if="$can(card, 'card.agree')"
            content="Согласовать"
            placement="top"
            :open-delay="500"
          >
          <span style="display:inline-block; margin-left: 15px">
            <el-button
              class="table-action-button"
              :disabled="!$rulesPass(card, 'card.agree')"
              type="success"
              round
              size="mini"
              icon="fas fa-check"
              @click="agreeNegotiation(card)"
            >
            </el-button>
          </span>
          </el-tooltip>


        </template>
        <template v-slot="scope" >
          {{ scope.row.cardsWeights[card.id] }}
        </template>
      </el-table-column>
    </el-table-column>
    <el-table-column label="" :width="810"></el-table-column>
    <el-table-column label="" :width="50"></el-table-column>
  </el-table>
</template>

<script>



import {mapGetters} from "vuex";

export default {
  name: "matrix-summary",
  components: {},

  props: {
    matrix: {type: Object, required: true},
    scroll: {type: Number, default: 0},
  },

  watch: {
    scroll: function(newVal, oldVal) {
      let el = document.querySelector('.matrix-summary-table .el-table__body-wrapper');
      el.scrollLeft = newVal;
      el = document.querySelector('.matrix-summary-table .el-table__header-wrapper');
      el.scrollLeft = newVal;
    }
  },

  computed: {
    summary(){
      const cardsWeights = {};
      for (const [sectionName, section] of Object.entries(this.matrix.sections)) {
        if( this.$companyConfiguration.cardSections.isSectionMultiplyElementsBonus(sectionName) ){
          // Для кэфов не нужно считать вес
          continue;
        }

        section.elements.forEach(element => {
          for (const [cardId, weight] of Object.entries(element.weight)) {
            if( !cardsWeights.hasOwnProperty(cardId) ){
              cardsWeights[cardId] = 0;
            }
            if( weight ) {
              cardsWeights[cardId] += +weight;
            }
          }
        })
      }
      return [{cardsWeights}];
    },
  },
  mounted() {
    let el = document.querySelector('.matrix-summary-table .el-table__body-wrapper');
    el.addEventListener('scroll', this.handleScroll);
  },
  data() {
    return {

    }
  },
  methods: {
    openCardInNewWindow(cardId){
      let route = this.$router.resolve({ name: 'Card', params: { id: cardId } });
      window.open(route.href);
    },
    agreeNegotiation(card){
      this.$api.card.agreeCard({
        card_id: card.id
      })
        .then((data) => {
          this.$notify.success({
            title: 'Сохранено',
            message: 'Этап успешно сохранен'
          });
        })
    },
    handleScroll(event){
      this.$emit('update:scroll', event.target.scrollLeft);
    },
  }
}
</script>


<style scoped lang="scss">
.el-divider.el-divider--horizontal.mini{
  margin: 5px 0;
}
</style>